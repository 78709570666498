<template>
  <div v-if="thread" class="card tweet dark-mode-bg shadow-lg">
    <div class="card-body container-fluid">
      <div
        class="row whitespace-pre-wrap break-words"
        v-html="formatTweet(thread.tweets[0].status)"
      ></div>
    </div>

    <hr />

    <div class="flex justify-end space-x-2 m-2">
      <base-button
        class="custom-button"
        type="primary"
        size="sm"
        @click="$emit('add-thread-to-queue', thread)"
        :disabled="shouldDisable"
        :loading="isLoading"
        >Add to queue</base-button
      >
      <base-button
        type="primary"
        class="custom-button"
        size="sm"
        :disabled="shouldDisable"
        :loading="isLoading"
        @click="$emit('show-ghostwriting-refusal-reason-modal', thread)"
        >Reject</base-button
      >
    </div>
  </div>
</template>

<script>
  import { formatTweet } from '@/util/formatTweet';

  export default {
    methods: {
      formatTweet(tweetText) {
        return formatTweet(tweetText);
      },
      async addThreadToQueue(draftThread) {
        const time = this.previousTime
          ? this.schedule.getNextTimeSlot(this.previousTime)
          : this.schedule.getNextTimeSlot();

        const thread = draftThread.toScheduledThread(time);

        thread.ghostwritingStatus = 'scheduled';

        this.threadsBeingUpdated = [...this.threadsBeingUpdated, thread.id];

        try {
          // The writer field is deleted since it is a Firebase Reference and it can't be converted to JSON
          delete thread.writer;

          await thread.update(this.currentUser, this.userProfile);

          this.$eventStore.ghostwriting.validateTweet();

          this.previousTime = time;

          this.$notify({ type: 'success', message: 'Tweet scheduled successfully!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error ocurred while trying to add the tweet to the queue.`,
            type: 'error',
          });
        } finally {
          this.threadsBeingUpdated = this.threadsBeingUpdated.filter(
            (threadId) => threadId !== thread.id
          );
        }
      },
    },
    props: {
      thread: {
        type: Object,
        default: null,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      shouldDisable: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
