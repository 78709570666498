<template>
  <modal :show="show" @close="$emit('close')" :class="[{ 'overlay--on': submitting }]">
    <div class="overlay" v-if="submitting">
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div slot="header" class="modal-title">
      Tweet Rejection
    </div>

    <div>
      <div class="space-y-6">
        <base-input
          type="text"
          placeholder="Reason (optional)"
          ref="ghostwritingRefusalReason"
          v-model="ghostwritingRefusalReason"
        />
        <div class="flex items-center justify-end mt-3 space-x-2">
          <base-button
            type="primary"
            class="btn text primary w-full lg:w-auto"
            @click="setGhostwritingThreadToRejected"
          >
            <span>{{ ghostwritingRefusalReason.length ? 'Reject' : 'Reject without a reason' }}</span>
          </base-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SwalModalMixin from '../Mixins/SwalModalMixin.vue';

  export default {
    methods: {
      async setGhostwritingThreadToRejected() {
        this.submitting = true;

        try {
          await this.$emit('set-ghostwriting-thread-to-rejected', this.ghostwritingRefusalReason);
        } finally {
          this.submitting = false;
          this.ghostwritingRefusalReason = '';
          this.$emit('close');
        }
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    props: {
      show: Boolean,
    },
    data() {
      return {
        submitting: false,
        ghostwritingRefusalReason: '',
      };
    },
    mixins: [SwalModalMixin],
  };
</script>
