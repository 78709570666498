<template>
  <div v-if="clientGhostwritingThreads">
    <template v-if="clientGhostwritingThreads.length === 0">
      No tweets were found.
    </template>

    <template v-else>
      <div class="container mx-auto my-8">
        <div class="mb-4">These tweets have been prepared for you:</div>

        <div class="space-y-6">
          <div v-bind:key="thread.id" v-for="thread in clientGhostwritingThreads">
            <ghostwriting-thread
              :thread="thread"
              :isLoading="threadsBeingUpdated.includes(thread.id)"
              :shouldDisable="threadsBeingUpdated.length > 0 && !threadsBeingUpdated.includes(thread.id)"
              @add-thread-to-queue="addThreadToQueue"
              @show-ghostwriting-refusal-reason-modal="showGhostwritingRefusalReasonModal"
            ></ghostwriting-thread>
          </div>
        </div>
      </div>

      <ghostwriting-refusal-modal
        :show="isGhostwritingRefusalReasonModalVisible"
        @close="closeGhostwritingRefusalReasonModal"
        @set-ghostwriting-thread-to-rejected="setGhostwritingThreadToRejected"
      ></ghostwriting-refusal-modal>
    </template>
  </div>

  <div v-else class="h-75vh flex items-center justify-center">
    <half-circle-spinner :size="72" :color="'#3f51b5'" style="margin: auto;" />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import SwalModalMixin from './Mixins/SwalModalMixin.vue';
  import GhostwritingRefusalModal from './Modals/GhostwritingRefusalModal.vue';
  import GhostwritingThread from '@/components/GhostwritingThread.vue';
  import { HalfCircleSpinner } from 'epic-spinners';

  export default {
    components: { GhostwritingRefusalModal, HalfCircleSpinner, GhostwritingThread },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'clientGhostwritingThreads']),
    },
    data() {
      return {
        isLoading: false,
        selectedThread: null,
        isGhostwritingRefusalReasonModalVisible: false,
        previousTime: null,
        threadsBeingUpdated: [],
      };
    },
    methods: {
      async addThreadToQueue(draftThread) {
        const time = this.previousTime
          ? this.schedule.getNextTimeSlot(this.previousTime)
          : this.schedule.getNextTimeSlot();

        const thread = draftThread.toScheduledThread(time);

        thread.ghostwritingStatus = 'scheduled';

        this.threadsBeingUpdated = [...this.threadsBeingUpdated, thread.id];

        try {
          // The writer field is deleted since it is a Firebase Reference and it can't be converted to JSON
          delete thread.writer;

          await thread.update(this.currentUser, this.userProfile);

          this.$eventStore.ghostwriting.validateTweet();

          this.previousTime = time;

          this.$notify({ type: 'success', message: 'Tweet scheduled successfully!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            text: `An error ocurred while trying to add the tweet to the queue.`,
            type: 'error',
          });
        } finally {
          this.threadsBeingUpdated = this.threadsBeingUpdated.filter(threadId => threadId !== thread.id);
        }
      },
      async setGhostwritingThreadToRejected(rejectionReason) {
        const draftThread = this.selectedThread;

        this.threadsBeingUpdated = [...this.threadsBeingUpdated, draftThread.id];

        draftThread.ghostwritingStatus = 'rejected';
        draftThread.ghostwritingRefusal = rejectionReason;


        try {
          await draftThread.update();

          this.$eventStore.ghostwriting.rejectTweet();

          this.$notify({ type: 'success', message: 'Tweet rejected successfully!' });
        } catch (error) {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to reject the tweet`,
            type: 'error',
          });
        } finally {
          this.threadsBeingUpdated = this.threadsBeingUpdated.filter(threadId => threadId !== draftThread.id);
        }
      },
      showGhostwritingRefusalReasonModal(thread) {
        this.selectedThread = thread;
        this.isGhostwritingRefusalReasonModalVisible = true;
      },
      closeGhostwritingRefusalReasonModal() {
        this.selectedThread = null;
        this.isGhostwritingRefusalReasonModalVisible = false;
      },
    },
    mixins: [SwalModalMixin],
  };
</script>

<style lang="scss">
  .h-75vh {
    height: 75vh !important;
  }
</style>
